import styled from "styled-components"
import { COLORS } from "../GlobalStyles"
import { useInView } from "react-intersection-observer"
import { useTranslation } from "react-i18next"

export const OurServicesText = () => {
  const { t } = useTranslation();
  const { ref, inView, entry } = useInView({
    triggerOnce: true,
    threshold: 0.5
  })
  return (
    <OurServicesContainer
      ref={ref}
      className={`Projects-Heading ${inView ? 'animate' : ''}`}
    >
      <h1>{t('our_services_title')}</h1>
      <p>
        {t('services_intro')}
      </p>
    </OurServicesContainer>
  )
}
const OurServicesContainer = styled.div`
margin-top: 3%;
  margin-left: 20%;
  margin-right: 20%;
  display: flex;
  flex-direction:column;
  align-items: center;
  @media (max-width: 768px) {
    text-align: center;
    
    margin-left: 10%;
    margin-right: 10%;
  }
    h1 {
      font-size: 52px;
      color:${COLORS.navy};
  }
    p {
      margin-top: -1%;
      font-size: 16px;
      color: black;
      text-align:center;
  }
  `