import { useState } from "react";
import styled from "styled-components";
import ContactPic from "../assets/wall2.jpeg";
import { COLORS } from "../GlobalStyles";
import { useTranslation } from "react-i18next";

export const Form = () => {
  const { t } = useTranslation()
  const [result, setResult] = useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "10971829-981a-421c-b149-0ede43d294e0");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  return (
    <Container>
      <div>
        <h1>{t('contact_us_title')}</h1>
        <p>{t('contact_us_intro')}</p>
      </div>
      <FormContents>
        <StyledImage src={ContactPic} />
        <StyledForm onSubmit={onSubmit}>
          <StyledInput type="text" placeholder={t('form_name_label')} name="name" required />
          <StyledInput type="email" placeholder={t('form_email_label')} name="email" required />
          <StyledTextArea
            name="message"
            placeholder={t('form_message_label')}
            required
          ></StyledTextArea>
          <StyledButton type="submit">{t('form_submit_button')}</StyledButton>
        </StyledForm>
      </FormContents>
    </Container>
  );
};
const Container = styled.div`
  color: ${COLORS.navy};
  margin-left: 10%;
  margin-right: 10%;

  @media (max-width: 576px) {
    height: 80vh;
    text-align: center;
  }
  @media (min-width: 576px) and (max-width: 767.98px) {
  }
`;
const FormContents = styled.div`
  height: 80%;
  display: flex;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px;
  width: 50%;
  @media (max-width: 575.98px) {
    width: 100%;
    justify-content: space-evenly;
  }
  @media (min-width: 576px) and (max-width: 767.98px) {
    justify-content: space-evenly;
  }
`;

const StyledInput = styled.input`
  border-radius: 20px;
  border: 1px solid ${COLORS.navy};
  width: 50%;
  padding: 10px;
  font-size: 16px;

  &:focus {
    outline: none;
    box-shadow: 0 0 3px ${COLORS.navy};
  }
  @media (max-width: 575.98px) {
    width: 100%;
  }
  @media (min-width: 576px) and (max-width: 767.98px) {
    width: 100%;
  }
`;

const StyledButton = styled.button`
  color: white;
  background-color: ${COLORS.navy};
  border: none;
  border-radius: 20px;
  padding: 14px;
  font-size: 16px;
  width: 50%;
  cursor: pointer;
  @media (max-width: 575.98px) {
    width: 100%;
  }
  @media (min-width: 576px) and (max-width: 767.98px) {
    width: 100%;
  }
`;

const StyledTextArea = styled.textarea`
  border-radius: 20px;
  border: none;
  padding: 10px;
  font-size: 16px;
  resize: vertical; /* Allow vertical resizing */
  width: 100%;
  min-height: 100px; /* Set a minimum height */
  border: 1px solid ${COLORS.navy};
  &:focus {
    outline: none;
    box-shadow: 0 0 3px ${COLORS.navy};
  }
`;

const StyledImage = styled.img`
  width: 50%;
  height: 500px;
  object-fit: cover;
  border-radius: 10px;
  @media (max-width: 575.98px) {
    display: none;
  }
  @media (min-width: 576px) and (max-width: 767.98px) {
  }
`;
