import styled from "styled-components";
import { COLORS } from "../GlobalStyles";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { InstagramOutlined } from "@ant-design/icons";
import { LinkedinOutlined } from "@ant-design/icons";
import { FacebookOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const Map = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDKj_8KV0K9lLzhmRz4TZNxA6S2zSslRF4", // Replace with your API key
  });

  const businessLocation = {
    lat: 45.485665,

    lng: -73.697634,
  };

  if (loadError) return "Error loading Google Maps";
  if (!isLoaded) return "Loading...";

  return (
    <MapContainer>
      <GoogleMap
        id="example-map"
        mapContainerStyle={{
          width: "100%",
          height: "100%",
        }}
        center={businessLocation}
        zoom={13}
      >
        <Marker position={businessLocation} />
      </GoogleMap>
    </MapContainer>
  );
};

export const Contact = () => {
  const { t } = useTranslation()
  return (
    <Container>
      <h1></h1>
      <ContactFlexContainer>
        <ContactInfo>
          <h3>{t('phone_number_label')}: (514) 435-5787 </h3>
          <h3>{t('email_address_label')}: Dylan@JacksonRenovationsInc.com</h3>
          <h3>
            {t('office_address_label')}: 3700 Rue Griffith Saint-Laurent, QC H4T 2B3 Office
            101
          </h3>
        </ContactInfo>
        <Map />
      </ContactFlexContainer>
      <Icons>
        <a
          href="https://www.facebook.com/jacksonrenovationsinc?mibextid=LQQJ4d"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledFacebookIcon />
        </a>
        <a
          href="https://www.instagram.com/jackson.constructions/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledInstagramIcon />
        </a>
        <a
          href="https://www.linkedin.com/company/jackson-renovations-inc/?originalSubdomain=ca"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledLinkedinIcon />
        </a>
      </Icons>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 5%;
  color: ${COLORS.navy};
  margin-left: 10%;
  margin-right: 10%;
  
`;

const ContactInfo = styled.div`
  color: ${COLORS.navy};
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 55%;
  @media (max-width: 575.98px) {
    width: 100%;
    justify-content: space-evenly;
    height: 40vh;
    text-align: center;
    h3 {
      font-size: 15px;
    }
  }
    @media (min-width: 576px) and (max-width: 767.98px) {
      width: 100%;
      text-align: center;
      height: 40vh;
      h3 {
        font-size: 18px;
        
      }
    }
  
`;

const ContactFlexContainer = styled.div`
  display: flex;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const MapContainer = styled.div`
  flex: auto;
  height: 400px;
  @media (max-width: 768px) {
    height: 50vh;
  }
`;

const StyledFacebookIcon = styled(FacebookOutlined)`
  color: ${COLORS.navy};
  font-size: 30px;
  width: fit-content;
  transition: all 200ms ease;
  cursor: pointer;
`;

const StyledLinkedinIcon = styled(LinkedinOutlined)`
  color: ${COLORS.navy};
  font-size: 30px;
  transition: all 200ms ease;
  width: fit-content;
  cursor: pointer;
`;

const StyledInstagramIcon = styled(InstagramOutlined)`
  font-size: 30px;
  color: ${COLORS.navy};
  width: fit-content;
  transition: all 200ms ease;
  cursor: pointer;
`;

const Icons = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-between;
  margin-top: -37px;
  @media (max-width: 768px) {
    margin-top: 2%
  }
`;
