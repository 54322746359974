
import styled from "styled-components"
import { Hero } from "./Components/Hero"

import { FreeQuote } from "./Components/FreeQuote"

import { LatestProjectsText } from "./Components/LatesProjectsText";
import { Projects } from "./Components/Projects";




const Container = styled.div`
  position: relative;
  max-width: 100%;
  overflow: hidden;
  `

export const LandingPage = () => {
  return (
    <Container>
      <Hero />
      <FreeQuote />
      <LatestProjectsText />
      <Projects />
    </Container >
  )
}


