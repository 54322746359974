import { createGlobalStyle } from 'styled-components';

export const COLORS = {
  platinum: "#EAEAE8",
  navy: "#041E38"
}

export const GlobalStyle = createGlobalStyle`
*{
  box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'Poppins', sans-serif;
    color: #FAFAFA;
    background-color: ${COLORS.platinum};
}`
