import styled from "styled-components";
import { COLORS } from "../GlobalStyles";
import { LightboxButton } from "./LightboxButton";
import { useInView } from "react-intersection-observer";
import "../App.css";
import { useTranslation } from "react-i18next";

import niceApartment from "../assets/nicekitchen.png";
import niceApartment2 from "../assets/nicecondo.jpeg";

import ProjectKitchen1 from "../assets/projectkitchen1.png";
import ProjectKitchen from "../assets/projectkitchen.png";

import ProjectBathroom from "../assets/projectbathroom.jpeg";
import ProjectBathroom1 from "../assets/projectbathroom1.png";
import ProjectBathroom2 from "../assets/projectbathroom2.png";
import ProjectBathroom3 from "../assets/projectbathroom3.jpeg";

export const Projects = () => {
  const { t } = useTranslation();
  const [ref1, inView1] = useInView({ threshold: 0.4, triggerOnce: true });
  const [ref2, inView2] = useInView({ threshold: 0.4, triggerOnce: true });
  const [ref3, inView3] = useInView({ threshold: 0.4, triggerOnce: true });

  const images = [
    { src: niceApartment, alt: "Kitchen 1" },
    { src: niceApartment2, alt: "Kitchen 1" },
  ];
  const images2 = [
    { src: ProjectKitchen, alt: "Kitchen 1" },
    { src: ProjectKitchen1, alt: "Kitchen 1" },
  ];

  const images3 = [
    { src: ProjectBathroom, alt: "Bathroom" },
    { src: ProjectBathroom1, alt: "Bathroom 1" },
    { src: ProjectBathroom2, alt: "Bathroom 2" },
    { src: ProjectBathroom3, alt: "Bathroom 3" },
  ];

  return (
    <>
      <LatestProjectContainer
        ref={ref1}
        className={`Projects ${inView1 ? "animate" : ""}`}
      >
        <LatestProjectContainerContents>
          <h3>{t("modern_apartment_renovation")}</h3>
          <ProjectText>{t("renovation_description")} </ProjectText>
          <LightboxButton label={t('view_all_images')} images={images} />
        </LatestProjectContainerContents>
        <StyledImgProject src={niceApartment2} />
      </LatestProjectContainer>

      <LatestProjectContainer
        ref={ref2}
        className={`Projects ${inView2 ? "animate" : ""}`}
      >
        <LatestProjectContainerContents>
          <h3>{t('modern_kitchen_renovation_title')}</h3>
          <ProjectText>
            {t('modern_kitchen_renovation_description')}
          </ProjectText>
          <LightboxButton label={t('view_all_images')} images={images2} />
        </LatestProjectContainerContents>
        <StyledImgProject src={ProjectKitchen} />
      </LatestProjectContainer>

      <LatestProjectContainer
        ref={ref3}
        className={`Projects ${inView3 ? "animate" : ""}`}
      >
        <LatestProjectContainerContents>
          <h3>{t('bathroom_transformation_title')}</h3>
          <ProjectText>
            {t('bathroom_transformation_description')}
          </ProjectText>
          <LightboxButton label={t('view_all_images')} images={images3} />
        </LatestProjectContainerContents>
        <StyledImgProject src={ProjectBathroom2} />
      </LatestProjectContainer>
    </>
  );
};

const LatestProjectContainer = styled.div`
  margin-top: 3%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 1%;
  color: black;
  height: 300px;
  background: white;
  border-radius: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  &:hover {
  }
  h3 {
    font-size: 24px;
    color: ${COLORS.navy};
  }
  @media (max-width: 768px) {
    flex-direction: column; /* Change direction to column for mobile */
    height: auto; /* Allow content to determine height */
    align-items: center;
    div {
      order: 1; /* Center items horizontally */
    }
  }
`;
const ProjectText = styled.p`
  font-size: 16px;

  @media (min-width: 767.5px) and (max-width: 1000px) {
    font-size: 12px;
  }
`;

const StyledImgProject = styled.img`
  height: 100%;
  width: 50%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  @media (max-width: 768px) {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 0px;
    width: 100%;
  }
`;
const LatestProjectContainerContents = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 50%;
  padding: 20px;

  @media (max-width: 768px) {
    width: 100%; /* Full width for mobile */
    text-align: center; /* Center text horizontally for mobile */
  }
`;
