import styled from "styled-components"
import { COLORS } from "../GlobalStyles"



export const Button = () => {
  return (
    <StyledButton>Read More</StyledButton>
  )
}

const StyledButton = styled.button`
  color: white;
  background-color: ${COLORS.navy};
  border: none;
  border-radius:20px;
  padding: 1rem;
  font-size: 20px;
  width: 200px;
`