import styled from "styled-components";
import { LuAlignJustify } from "react-icons/lu";
import { COLORS } from "../GlobalStyles";
import jacksonleft from "../assets/jacksonleft.png";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "antd";
import { useTranslation } from "react-i18next";
export const Navbar = () => {
  const { i18n, t } = useTranslation();


  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate("/");
  };

  const handleAboutUsClick = () => {
    navigate("/About-Us");
  };

  const handleOurServicesClick = () => {
    navigate("/Our-Services");
  };
  const handleContactUs = () => {
    navigate("/Contact-Us");
  };
  const items = [
    {
      label: <P onClick={handleAboutUsClick}>{t('about_us')}</P>,
      key: "0",
    },
    {
      label: <P onClick={handleOurServicesClick}>{t('our_services_title')}</P>,
      key: "1",
    },
    {
      label: <P onClick={handleContactUs}>{t('contact_us_title')}</P>,
      key: "2",
    },
  ];

  return (
    <NabvarContainer>
      <div>
        <img onClick={handleLogoClick} src={jacksonleft} />
      </div>

      <Contents>
        <p onClick={handleAboutUsClick}>{t('about_us')}</p>
        <p onClick={handleOurServicesClick}>{t('our_services_title')}</p>
        <p onClick={handleContactUs}>{t('contact_us_title')}</p>
      </Contents>
      <Dropdown
        menu={{
          items,
          style: { backgroundColor: `${COLORS.platinum}` },
        }}
        placement="bottom"
        trigger={["click"]}
      >
        <Hambergur>
          <a onClick={(e) => e.preventDefault()}></a>
          <LuAlignJustify />
        </Hambergur>
      </Dropdown>
    </NabvarContainer>
  );
};
const NabvarContainer = styled.div`
  display: flex;
  color: ${COLORS.navy};
  margin-left: 10%;
  margin-right: 10%;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  img {
    height: 150px;
    cursor: pointer;
    transition: all 200ms ease;
    &:hover {
      scale: 1.05;
    }
  }
`;
const Contents = styled.div`
  display: flex;
  width: 60%;
  flex-direction: row;
  justify-content: space-between;

  p {
    transition: all 200ms ease;
    padding: 12px;
    cursor: pointer;
    &:hover {
      scale: 1.05;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

const Hambergur = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;
const P = styled.p`
  color: ${COLORS.navy};
  font-weight: 500;
  font-style: italic;
`;
const Button = styled.button`
background: transparent;
border: none;
text-decoration: underline;
cursor: pointer;
`
