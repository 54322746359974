import React, { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { DoubleRightOutlined } from '@ant-design/icons';
import styled from "styled-components"
import { COLORS } from "../GlobalStyles";

export const LightboxButton = ({ label, images }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button type="button" onClick={() => setOpen(true)}>
        {label}
        <DoubleRightOutlined />
      </Button>

      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={images.map((image) => ({
          src: image.src,
          alt: image.alt,
          width: image.width || 1200,
          height: image.height || 800,
        }))}
      />
    </>
  );
};
const Button = styled.button`
font-size: 24px;
color: ${COLORS.navy};
background: none;
border: none;
cursor: pointer;
`

