import { LightboxButton } from "./Components/LightboxButton.js";
import image1 from "../src/assets/jackson.jpeg";
import image2 from "../src/assets/Wall.jpeg";
import image3 from "../src/assets/wall2.jpeg";
import styled from "styled-components";
import videoBg from "./assets/Slideshow.mp4";
import logo from "./assets/jackson.png";
import { COLORS } from "./GlobalStyles.js";
import { useNavigate } from "react-router-dom";
import { FreeQuote } from "./Components/FreeQuote.js";
import { useTranslation } from "react-i18next";

export const AboutUs = () => {

  const { t } = useTranslation()
  const navigate = useNavigate();
  const handleContactUs = () => {
    navigate("/Contact-Us");
  };
  const images1 = [
    { src: image1, alt: "Image 1" },
    { src: image2, alt: "Image 2" },
  ];

  const images2 = [{ src: image3, alt: "Image 3" }];

  return (
    <>
      <HeroContainer>
        <Overlay></Overlay>
        <video src={videoBg} autoPlay loop muted playsInline />
        <Content>
          <img src={logo} />
          <p>
            {t('slogan_for_about_us_page')}
          </p>
          <button onClick={handleContactUs}>{t('get_a_quote')}</button>
        </Content>
      </HeroContainer>
      <Contents>
        <p>
          {t('about_us_page')}
        </p>
      </Contents>
      <QuoteCard>
        <QuoteCardContents>
          <h1>{t('get_a_free_quote')}</h1>
          <p>
            {t("quote_line_1")}
            <br />
            {t("quote_line_2")}
            <br />
            {t("quote_line_3")}
          </p>
        </QuoteCardContents>
        <QuoteButtonContainer>
          <QuoteButton onClick={handleContactUs}>{t('get_a_quote')}</QuoteButton>

        </QuoteButtonContainer>
      </QuoteCard>
    </>
  );
};
const HeroContainer = styled.div`
  position: relative; /* Change from absolute to relative */
  width: 100%;
  height: 100vh;
  overflow: hidden;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const Content = styled.div`
  position: absolute;
  width: 60%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  img {
    max-height: 40%;
    max-width: 40%;
    width: auto;
    height: auto;
  }
  p {
    font-size: 20px;
    color: ${COLORS.platinum};
    font-style: italic;
  }
  button {
    width: 30%;
    border-radius: 20px;
    background-color: ${COLORS.navy};
    padding: 0.5rem;
    color: white;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    width: 80%;
    img {
      max-height: 100%; /* Increase image height */
      max-width: 100%; /* Increase image width */
    }

    p {
      font-size: 12px;
    }

    button {
      width: 60%; /* Increase button width */
      font-size: 12px; /* Increase button font size */
    }
  }

  @media (max-width: 767px) and (orientation: landscape) {
    width: 50%;

    img {
      max-height: 60%;
      max-width: 60%;
    }

    p {
      font-size: 12px;
    }

    button {
      width: 40%;
      font-size: 16px;
    }
  }
  @media (min-width: 768px) and (max-width: 990px) {
    width: 60%;

    img {
      max-height: 80%;
      max-width: 80%;
    }

    p {
      font-size: 18px;
    }

    button {
      width: 40%;
      font-size: 14px;
    }
  }
`;
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* background-color: #000000cc; */
`;

const Contents = styled.div`
color: ${COLORS.navy};
margin-left: 10%;
margin-right: 10%;
margin-top: 4%;

`

const QuoteCard = styled.div`
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-left: 10%;
  margin-right: 10%;
  padding: 2.5rem;
  border-radius: 20px;
  background-color: ${COLORS.navy};
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    text-align: center;
    align-items: center;
    margin-top: 15%;
    p br {
      display: none;
    }
  }
  `
const QuoteCardContents = styled.div`
  `
const QuoteButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  @media (max-width: 768px) {
    gap: 15px;
  }
`
const QuoteButton = styled.button`
  color: black;
  background-color: ${COLORS.platinum};
  width: 200px;
  padding: 1rem;
  border: none;
  border-radius: 20px;
  font-size: 15px;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor:pointer;
  `