import { LightboxButton } from "./LightboxButton";
import { useTranslation } from "react-i18next";

import styled from "styled-components";
import { Card } from "antd";
import { COLORS } from "../GlobalStyles";
import { useInView } from "react-intersection-observer";

import InteriorFinishing from "../assets/InteriorFinishing.png";
import InteriorFinishing1 from "../assets/InteriorFinishing1.png";
import InteriorFinishing2 from "../assets/InteriorFinishing2.jpg";

import niceApartment from "../assets/nicekitchen.png";
import niceApartment2 from "../assets/nicecondo.jpeg";

import ProjectKitchen1 from "../assets/projectkitchen1.png";
import ProjectKitchen from "../assets/projectkitchen.png";

import ProjectBathroom from "../assets/projectbathroom.jpeg";
import ProjectBathroom1 from "../assets/projectbathroom1.png";
import ProjectBathroom2 from "../assets/projectbathroom2.png";
import ProjectBathroom3 from "../assets/projectbathroom3.jpeg";
import ServicesBathroom from "../assets/ServicesBathroom.png";
import ServicesBathroom1 from "../assets/ServicesBathroom1.png";
import ServicesBathroom2 from "../assets/ServicesBathroom2.png";
import ServicesBathroom3 from "../assets/ServicesBathroom3.jpeg";
import ServicesBathroom4 from "../assets/ServicesBathroom4.png";

import cle from "../assets/cle.jpeg";
import cle1 from "../assets/cle1.png";
import cle3 from "../assets/cle3.jpg";

import wall from "../assets/Wall.jpeg";
import wall1 from "../assets/wall2.jpeg";

import ServicesKitchen from "../assets/ServicesKitchen2.png";

export const Services = () => {
  const { t } = useTranslation()
  const [ref1, inView1] = useInView({ threshold: 0.2, triggerOnce: true });
  const [ref2, inView2] = useInView({ threshold: 0.4, triggerOnce: true });
  const [ref3, inView3] = useInView({ threshold: 0.2, triggerOnce: true });
  const [ref4, inView4] = useInView({ threshold: 0.4, triggerOnce: true });

  const images = [
    { src: niceApartment2, alt: "Kitchen 1" },
    { src: InteriorFinishing1, alt: "Interior Finishing" },
    { src: InteriorFinishing, alt: "Interior Finishing 1" },
    { src: InteriorFinishing2, alt: "Interior Finishing 2" },
    { src: wall, alt: "Interior Finishing 2" },
    { src: wall1, alt: "Interior Finishing 2" },
  ];
  const images2 = [
    { src: ProjectKitchen, alt: "Kitchen 1" },
    { src: ProjectKitchen1, alt: "Kitchen 1" },
    { src: niceApartment, alt: "Kitchen 1" },
    { src: ServicesKitchen, alt: "Kitchen 1" },
  ];

  const images3 = [
    { src: ProjectBathroom, alt: "Bathroom" },
    { src: ProjectBathroom1, alt: "Bathroom 1" },
    { src: ProjectBathroom2, alt: "Bathroom 2" },
    { src: ProjectBathroom3, alt: "Bathroom 3" },
    { src: ServicesBathroom, alt: "Bathroom 4" },
    { src: ServicesBathroom1, alt: "Bathroom 5" },
    { src: ServicesBathroom2, alt: "Bathroom 6" },
    { src: ServicesBathroom3, alt: "Bathroom 7" },
    { src: ServicesBathroom4, alt: "Bathroom 8" },
  ];
  const images4 = [
    { src: cle, alt: "Cle" },
    { src: cle1, alt: "Cle" },
    { src: cle3, alt: "Cle" },
  ];
  return (
    <>
      <OurServicesCardContainer>
        <CustomCard
          ref={ref1}
          className={`Projects ${inView1 ? 'animate' : ''}`}
          cover={
            <img
              alt="example"
              src={InteriorFinishing1}
              style={{ height: "217px" }}
            />
          }
        >
          <CustomTitle>{t('interior_finishing_title')}</CustomTitle>
          <CustomDescription>
            {t('interior_finishing_description')}
          </CustomDescription>
          <LightboxButton label={t('view_all_images')} images={images} />
        </CustomCard>
        <CustomCard
          ref={ref2}
          className={`Projects ${inView2 ? 'animate' : ''}`}
          cover={
            <img
              alt="example"
              src={ProjectKitchen1}
              style={{ height: "217px" }}
            />
          }
        >
          <CustomTitle>{t('kitchen_renovation_title')}</CustomTitle>
          <CustomDescription>
            {t('kitchen_renovation_description')}
          </CustomDescription>
          <LightboxButton label={t('view_all_images')} images={images2} />
        </CustomCard>
        <CustomCard
          ref={ref3}
          className={`Projects ${inView3 ? 'animate' : ''}`}
          cover={
            <img
              alt="example"
              src={ServicesBathroom}
              style={{ height: "217px" }}
            />
          }
        >
          <CustomTitle>{t('bathroom_remodeling_title')}</CustomTitle>
          <CustomDescription>
            {t('bathroom_remodeling_description')}
          </CustomDescription>
          <LightboxButton label={t('view_all_images')} images={images3} />
        </CustomCard>

        <CustomCard
          ref={ref4}
          className={`Projects ${inView4 ? 'animate' : ''}`}
          cover={<img alt="example" src={cle1} style={{ height: "217px" }} />}
        >
          <CustomTitle>{t('turnkey_title')}</CustomTitle>
          <CustomDescription>
            {t('turnkey_description')}
          </CustomDescription>
          <LightboxButton label={t('view_all_images')} images={images4} />
        </CustomCard>
      </OurServicesCardContainer>
    </>
  );
};

const OurServicesCardContainer = styled.div`
  margin-top: 5%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  text-align: center;

  margin-left: 20%;
  margin-right: 20%;
  
  @media (max-width: 1100px) {
    margin-left: 10%;
    margin-right: 10%;
  }

  @media (max-width: 767.5px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const CustomTitle = styled.h3`
  margin-top: 0px;
  font-size: 26px;
  color: ${COLORS.navy};
`;
const CustomDescription = styled.p`
  font-size: 14px;
  margin-bottom: 50px;
`;
const CustomCard = styled(Card)`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);

  position: relative; /* Ensure positioning context */

  /* Ensure the card content grows to fill available space */
  flex-grow: 1;

  /* Nested styling for button */
  button {
    color: ${COLORS.platinum};
    border-radius: 20px;
    font-size: 14px;
    padding: 8px;
    width: 40%;
    background: ${COLORS.navy};
    border: none;

    /* Positioning the button at the bottom */
    position: absolute;
    bottom: 10px; /* Adjust as needed */
    left: 50%; /* Aligning horizontally */
    transform: translateX(-50%); /* Center horizontally */
  }
`;
