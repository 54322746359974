import React from "react";
import { styled } from 'styled-components'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { Navbar } from "./Components/Navbar";
import { GlobalStyle } from "./GlobalStyles";
import { LandingPage } from "./LandingPage";
import { OurServies } from "./OurServices";
import { AboutUs } from "./AboutUs";
import { Footer } from "./Components/Footer";
import { ContactUs } from "./ContactUs";



function App() {
  return (
    <AppContainer>
      <BrowserRouter>
        <GlobalStyle />
        <Navbar />
        <RouteContianer>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/Our-Services" element={<OurServies />} />
            <Route path="/About-Us" element={<AboutUs />} />
            <Route path="/Contact-Us" element={<ContactUs />} />
          </Routes>
        </RouteContianer>
        <Footer />
      </BrowserRouter>
    </AppContainer>
  )
}


const AppContainer = styled.div`
max-width: 100vw;
`
const RouteContianer = styled.div`
`
export default App;
