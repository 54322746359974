import styled from 'styled-components';
import { InstagramOutlined } from '@ant-design/icons';
import { LinkedinOutlined } from '@ant-design/icons';
import { FacebookOutlined } from '@ant-design/icons';
import footerlogo from "../assets/jacksonlogo.png"
import { useTranslation } from "react-i18next";
import { COLORS } from '../GlobalStyles';


export const Footer = () => {
  const { i18n, t } = useTranslation();
  const toggleLanguage = () => {
    const newLanguage = i18n.language === "en" ? "fr" : "en";
    i18n.changeLanguage(newLanguage);
  };
  return (
    <FooterContents>
      <FooterContainer>
        <div>

          <StyledFooterLogo src={footerlogo} />
        </div>

        <Content>
          <h3>CONTACT</h3>
          <p>3700 Rue Griffith
            Saint-Laurent,<br />
            QC H4T 2B3
            Office 101
          </p>
          <p>Dylan@JacksonRenovationsInc.com</p>
          <p>(514) 435-5787</p>
        </Content>

        <div>
          <a href='https://www.facebook.com/jacksonrenovationsinc?mibextid=LQQJ4d' target="_blank" rel="noopener noreferrer"><StyledFacebookIcon /></a>
          <a href='https://www.instagram.com/jackson.constructions' target="_blank" rel="noopener noreferrer"><StyledInstagramIcon /></a>
          <a href='https://www.linkedin.com/company/jackson-renovations-inc/?originalSubdomain=ca' target="_blank" rel="noopener noreferrer"><StyledLinkedinIcon /></a>
          <Button onClick={toggleLanguage}>
            {i18n.language === "en" ? "Fr" : "En"}
          </Button>
        </div>


      </FooterContainer>
    </FooterContents>
  );
};

const FooterContents = styled.div`
margin-top:5%;
box-sizing: border-box;
background-color: rgba(4, 30, 56);
`

const StyledFacebookIcon = styled(FacebookOutlined)`
  font-size: 30px;
  width: fit-content;
  transition: all 200ms ease;
  cursor: pointer;
  opacity: 0.5;
    &:hover {
    opacity: 1;
  }
`;

const StyledLinkedinIcon = styled(LinkedinOutlined)`
font-size: 30px;
transition: all 200ms ease;
width: fit-content;
opacity: 0.5;
cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;

const StyledInstagramIcon = styled(InstagramOutlined)`
font-size: 30px;
width: fit-content;
transition: all 200ms ease;
cursor: pointer;
opacity: 0.5;
  &:hover {
    opacity: 1;
  }
`;

const StyledFooterLogo = styled.img`
width: 300px;


@media (max-width: 768px) {
  transform: rotate(90deg);
width:150px;
margin-left:-57px;
}

`
const FooterContainer = styled.div`
margin-left:10%;
margin-right: 10%;
display: flex;
flex-direction: row;

justify-content: space-between;

@media ( max-width: 768px){
  margin: 15px;
}

div {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  line-height: 1;

  a {
    text-decoration: none;
    color: inherit;
  }
}
`

const Content = styled.div`
@media ( max-width: 768px){
}
p {
  font-size: 10px;
}`

const Button = styled.button`
background: transparent;
border: none;
text-decoration: underline;
cursor: pointer;
color: ${COLORS.platinum};
`
