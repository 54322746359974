import { COLORS } from "../GlobalStyles";
import logo from "../assets/jackson.png";
import { useNavigate } from "react-router-dom";
import videoBg from "../assets/Slideshow.mp4";
import styled from "styled-components";
import "../App.css";
import { useTranslation } from "react-i18next";

export const Hero = () => {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const handleContactUs = () => {
    navigate("/Contact-Us");
    window.scrollTo(0, 0);
  };
  return (
    <HeroContainer>
      <Overlay></Overlay>
      <video src={videoBg} autoPlay loop muted playsInline />
      <Content className="Hero-Content">
        <img src={logo} />
        <p>{t('slogan')}</p>
        <button onClick={handleContactUs}>{t('contact')}</button>
      </Content>
    </HeroContainer>
  );
};

const HeroContainer = styled.div`
  position: relative; /* Change from absolute to relative */
  width: 100%;
  height: 100vh;
  overflow: hidden;
text-align: center;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Content = styled.div`
  img {
    max-height: 40%;
    max-width: 40%;
    width: auto;
    height: auto;
    
  }
  p {
    
    font-size: 20px;
    color: ${COLORS.platinum};
    font-style: italic;
  }
  button {
    width: 30%;
    border-radius: 20px;
    background-color: ${COLORS.navy};
    padding: 0.5rem;
    color: white;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    width: 80%;
    img {
      max-height: 100%; /* Increase image height */
      max-width: 100%; /* Increase image width */
    }

    p {
      font-size: 12px;
    }

    button {
      width: 60%; /* Increase button width */
      font-size: 12px; /* Increase button font size */
    }
  }

  @media (max-width: 767px) and (orientation: landscape) {
    width: 50%;

    img {
      max-height: 60%;
      max-width: 60%;
    }

    p {
      font-size: 12px;
    }

    button {
      width: 40%;
      font-size: 16px;
    }
  }
  @media (min-width: 768px) and (max-width: 990px) {
    width: 60%;

    img {
      max-height: 80%;
      max-width: 80%;
    }

    p {
      font-size: 18px;
    }

    button {
      width: 40%;
      font-size: 14px;
    }
  }
`;
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* background-color: #000000cc; */
`;
