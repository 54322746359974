import styled from "styled-components"
import { COLORS } from "../GlobalStyles"
import { useState } from "react"
import { IoIosArrowDown } from "react-icons/io";
import { useTranslation } from "react-i18next";



export const FAQ = () => {
  const [clicked, setClicked] = useState()
  const { t } = useTranslation()

  const toggleClicked = () => {
    setClicked(!clicked)
  }
  const faqData = [
    {
      question: t('faq_intro'),
      answer: t('faq_intro_answer'),
    },
    {
      question: t('faq_start_project'),
      answer: t('faq_start_answer'),
    },
    {
      question: t('faq_cost'),
      answer: t('faq_cost_answer'),
    },
    {
      question: t('faq_design'),
      answer: t('faq_design_answer'),
    },
    {
      question: t('faq_duration'),
      answer: t('faq_duration_answer'),
    },
    {
      question: t('faq_materials'),
      answer: t('faq_materials_answer'),
    },
    {
      question: t('faq_workers'),
      answer: t('faq_workers_answer'),
    },
    {
      question: t('faq_support'),
      answer: t('faq_support_answer'),
    },
    {
      question: t('faq_payment_methods'),
      answer: t('faq_payment_methods_answer'),
    },

  ]
  return (
    <OurServicesContainer>
      <Container>
        <h1>FAQ</h1>
        <ArrowIcon onClick={toggleClicked} clicked={clicked} />
      </Container>
      {clicked &&
        faqData.map((faq, index) => (
          <FaqDataContainer key={index}>
            <h3> {faq.question}</h3>
            <p> {faq.answer}</p>
          </FaqDataContainer>
        ))}
    </OurServicesContainer>
  )
}

const OurServicesContainer = styled.div`
margin-top: 5%;
  margin-left: 20%;
  margin-right: 20%;
  display: flex;
  flex-direction:column;
  @media (max-width: 768px) {
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
  }
    h1 {
      font-size: 52px;
      color:${COLORS.navy};
  }
  
  `

const FaqDataContainer = styled.div`
  color: ${COLORS.navy};
  display: flex;
  flex-direction: column;
  padding: 10px;
  `

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  `

const ArrowIcon = styled(IoIosArrowDown)`
font-size: 28px;
color: black;
transform: ${(props) => (props.clicked ? "rotate(180deg)" : "rotate(0deg)")};
transition: transform 0.3s ease;
cursor: pointer;
`;