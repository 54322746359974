import styled from "styled-components";
import { COLORS } from "../GlobalStyles";
import { useNavigate } from "react-router-dom";
import "../App.css";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";

export const FreeQuote = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { ref, inView, entry } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const handleContactUs = () => {
    navigate("/Contact-Us");
    window.scrollTo(0, 0);
  };

  const handleAboutUsClick = () => {
    navigate("/About-Us");
    window.scrollTo(0, 0);
  };

  return (
    <QuoteCard ref={ref} className={`Quote-Card ${inView ? "animate" : ""}`}>
      <QuoteCardContents>
        <h1>{t("get_a_free_quote")}</h1>
        <p>
          {t("quote_line_1")}
          <br />
          {t("quote_line_2")}
          <br />
          {t("quote_line_3")}
        </p>
      </QuoteCardContents>
      <QuoteButtonContainer>
        <QuoteButton onClick={handleContactUs}>{t('get_a_quote')}</QuoteButton>
        <QuoteButton onClick={handleAboutUsClick}>{t('about_us')}</QuoteButton>
      </QuoteButtonContainer>
    </QuoteCard>
  );
};

const QuoteCard = styled.div`
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-left: 10%;
  margin-right: 10%;
  padding: 2.5rem;
  border-radius: 20px;
  background-color: ${COLORS.navy};
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    text-align: center;
    align-items: center;
    margin-top: 15%;
    p br {
      display: none;
    }
  }
`;
const QuoteCardContents = styled.div``;
const QuoteButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  @media (max-width: 768px) {
    gap: 15px;
  }
`;
const QuoteButton = styled.button`
  color: black;
  background-color: ${COLORS.platinum};
  width: 200px;
  padding: 1rem;
  border: none;
  border-radius: 20px;
  font-size: 15px;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
`;
