import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

i18n
  .use(initReactI18next)  // Passes i18n down to react-i18next
  .use(HttpApi)           // Allows loading translations over a backend
  .init({
    lng: 'en',            // Default language
    fallbackLng: 'en',    // Fallback language when a translation is not available
    debug: true,          // Enable debug mode if needed

    // Configuration for the backend loader
    backend: {
      loadPath: '/locales/{{lng}}/global.json',  // Path where resources get loaded from
    },

    interpolation: {
      escapeValue: false,  // Not needed for React as it escapes by default
    }
  });

export default i18n;